import type { App } from 'vue'
import { Locale } from 'vant'
import { createI18n } from 'vue-i18n'
import enUS from 'vant/es/locale/lang/en-US'
import ruRU from 'vant/es/locale/lang/ru-RU'
import koKR from 'vant/es/locale/lang/ko-KR'
import trTR from 'vant/es/locale/lang/tr-TR'
import esES from 'vant/es/locale/lang/es-ES'
import idID from 'vant/es/locale/lang/id-ID'
import bnBD from 'vant/es/locale/lang/bn-BD'
import viVN from 'vant/es/locale/lang/vi-VN'
import hiIN from 'vant/es/locale/lang/hi-IN'
import frFR from 'vant/es/locale/lang/fr-FR'
import { LOCALE_LIST } from '@/constants/locale'
import { setDayjsLocale } from '@/utils/date'
import locales from '@/locales/index'

type MessageSchema = typeof locales.en

// get current locale
export function getCurrentLocale(): string {
  const localeIndex = LOCALE_LIST.findIndex(item => item.value === navigator.language)
  if (localeIndex > -1)
    return LOCALE_LIST[localeIndex].value
  else
    return 'en'
}

// i18n init
const locale = getCurrentLocale()
export const i18n = createI18n<[MessageSchema], string>({
  messages: {
    en: locales.en,
    ru: locales.ru,
    ko: locales.ko,
    tr: locales.tr,
    id: locales.id,
    bn: locales.bn,
    vi: locales.vi,
    hi: locales.hi,
    es: locales.es,
    fr: locales.fr,
  },
  // set locale
  locale,
  // set fallback locale
  fallbackLocale: 'en',
  // set global, $t
  globalInjection: true,
  // to use Composition API
  legacy: false,
})

function setVantLocale(lang: string) {
  switch (lang) {
    case 'ru':
      Locale.use('ru-RU', ruRU)
      break
    case 'ko':
      Locale.use('ko-KR', koKR)
      break
    case 'tr':
      Locale.use('tr-TR', trTR)
      break
    case 'es':
      Locale.use('es-ES', esES)
      break
    case 'id':
      Locale.use('id-ID', idID)
      break
    case 'bn':
      Locale.use('bn-BD', bnBD)
      break
    case 'vi':
      Locale.use('vi-VN', viVN)
      break
    case 'hi':
      Locale.use('hi-IN', hiIN)
      break
    case 'fr':
      Locale.use('fr-FR', frFR)
      break
    default: Locale.use('en-US', enUS)
  }
}

// set global locale
export function setGlobalLocale(lang: string) {
  lang = lang || 'en'
  const _locale = i18n.global.locale as any
  if (_locale && _locale.value)
    _locale.value = lang

  // update lib locale
  setVantLocale(lang)
  setDayjsLocale(lang)
}

// config i18n
export function setupI18n(app: App) {
  app.use(i18n)
  setVantLocale(locale)
  setDayjsLocale(locale)
}
