export const ENV = {
  // BASE
  SENTRY_KEY: import.meta.env.VITE_SENTRY_KEY ?? '',
  API_CLIENT_URL: import.meta.env.VITE_API_CLIENT_URL ?? '',
  API_TON_CHAIN_URL: import.meta.env.VITE_API_TON_CHAIN_URL ?? '',
  TELEGRAM_BOT_ID: import.meta.env.VITE_TELEGRAM_BOT_ID ?? '',

  // AWS Clickstream
  CLICKSTREAM_APP_ID: import.meta.env.VITE_AWS_CLICKSTREAM_APP_ID ?? '',
  CLICKSTREAM_ENDPOINT: import.meta.env.VITE_AWS_CLICKSTREAM_ENDPOINT ?? '',

  // Share
  TG_BOT_LINK: import.meta.env.VITE_TG_BOT_LINK ?? '',
  TG_SHARE_LINK: import.meta.env.VITE_TG_SHARE_LINK ?? '',
  TG_CHANNEL_LINK: import.meta.env.VITE_TG_CHANNEL_LINK ?? '',
  TW_FOLLOW_LINK: import.meta.env.VITE_TW_FOLLOW_LINK ?? '',
  YESCOIN_REPOST_LINK: import.meta.env.VITE_YESCOIN_REPOST_LINK ?? '',

  // Constants
  OKX_LINK: 'https://www.ouxyi.link/ul/wVd8l3',
  BITGET_LINK: 'https://share.bwb.site/zh-CN/tools/taskPage?uuid=2f4fcd61-d34c-4c75-a41d-6edd2f3ec78d&_nocache=true&_needChain=ton',
  TG_GROUP_LINK25: 'https://t.me/theYescoin_chat25',
  TG_GROUP_LINK26: 'https://t.me/theYescoin_chat26',
  TG_CHANNEL_ACTIVITY_LINK: 'https://t.me/theYescoin/312',
  TON_COOP_LINK: 'https://x.com/Yescoin_Fam/status/1804181146001117629',
  YOUTUBE_LINK: 'https://www.youtube.com/@Yescoin_official',
  INSTAGRAM_LINK: 'https://www.instagram.com/yescoin_officialnews/',
  RULE_DETAIL_LINK: 'https://yescoin.notion.site/Yescoin-a-full-guide-39cfe894ac3f4198ad25826bd7b2889c',
  USER_PRIVACY_POLICY: 'https://yescoin.notion.site/User-Privacy-Policy-03399a10bec547e6a5dfd4d935565d47',
  YES_CRAZY_RULE_LINK: 'https://x.com/Yescoin_Fam/status/1796182601641709887',
  TUTORIAL_VIDEO_LINK: 'https://www.notion.so/yescoin/Wallet-Connect-Guide-a010bc81cece4a0d9135133c6a1c9f5d',

  // S3
  AWS_S3_BUCKET_NAME: import.meta.env.VITE_AWS_S3_BUCKET_NAME ?? '',

  // COIN MAX COUNT
  COIN_MAX_COUNT: 35,
  // COIN DEFAULT WIDTH
  COIN_DEFAULT_WIDTH: 78,
  // COIN DEFAULT HEIGHT
  COIN_DEFAULT_HEIGHT: 85,
  // COIN ROTATE MAX DEGREE
  COIN_ROTATE_MAX_DEGREE: 30,
  // COIN ROTATE MIN DEGREE
  COIN_ROTATE_MIN_DEGREE: -30,
  // COIN PARTICLE MIN RADIUS
  COIN_PARTICLE_MIN_RADIUS: 6,
  // COIN PARTICLE MAX RADIUS
  COIN_PARTICLE_MAX_RADIUS: 30,
  // COIN PARTICLE COUNT
  COIN_PARTICLE_COUNT: 140, // 60hz and above
  COIN_PARTICLE_MIDDLE_COUNT: 70, // 30hz ~ 60hz
  COIN_PARTICLE_LOW_COUNT: 35, // 30hz and below
  // COIN SCALE INIT TIMESTAMP
  COIN_SCALE_INIT_TIMESTAMP: 320,
  // CHEST DEFAULT WIDTH
  CHEST_DEFAULT_WIDTH: 74,
  // CHEST DEFAULT HEIGHT
  CHEST_DEFAULT_HEIGHT: 60,
  // CHEST OPEN WIDTH
  CHEST_OPEN_WIDTH: 74,
  // CHEST OPEN HEIGHT
  CHEST_OPEN_HEIGHT: 84,
  // CHEST SCALE INIT TIMESTAMP
  CHEST_SCALE_INIT_TIMESTAMP: 320,
  // CHEST ROTATE INIT TIMESTAMP
  CHEST_ROTATE_INIT_TIMESTAMP: 640,
  // CHEST COIN DEFAULT WIDTH
  CHEST_COIN_DEFAULT_WIDTH: 81,
  // CHEST COIN DEFAULT HEIGHT
  CHEST_COIN_DEFAULT_HEIGHT: 90,
  // AUTO BOT DEFAULT WIDTH
  AUTO_BOT_DEFAULT_WIDTH: 120,
  // AUTO BOT DEFAULT HEIGHT
  AUTO_BOT_DEFAULT_HEIGHT: 108,
  // BOOSTER MAX LEVEL
  BOOSTER_MAX_LEVEL: 20,
  // SWIPE BOT BOOSTER MAX LEVEL
  SWIPE_BOT_BOOSTER_MAX_LEVEL: 5,
  // COIN COLLECT WIDTH
  COIN_COLLECT_WIDTH: 39,
  // COIN COLLECT HEIGHT
  COIN_COLLECT_HEIGHT: 42,
}
