import { get } from '../client'

function list(query: any) {
  return get('/account/getRankingList', { params: query })
}

function yesCrazyList() {
  return get('/operation/weeklyInviteRank')
}

function yesCrazyResultList(config: any) {
  return get('/yes-crazy.json', config)
}

export default {
  list,
  yesCrazyList,
  yesCrazyResultList,
}
