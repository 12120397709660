import { get, post } from '../client'

function info() {
  return get('/task/mainPage')
}

function list() {
  return get('/task/getTaskList')
}

function finishBonus() {
  return get('/task/getFinishTaskBonusInfo')
}

function commonList() {
  return get('/task/getCommonTaskList')
}

function inviteList() {
  return get('/task/getInviteTaskList')
}

function dailyList() {
  return get('/mission/getDailyMission')
}

function skinList(query: any) {
  return get('/task/getCryptocurrencyExchangeTaskList', { params: query })
}

function upgradeList() {
  return get('/task/getUserUpgradeTaskList')
}

function dailyClick(data: any, config?: any) {
  return post('/mission/clickDailyMission', data, config)
}

function commonClick(data: any, config?: any) {
  return post('/task/clickTask', data, config)
}

function dailyCheck(data: any, config?: any) {
  return post('/mission/checkDailyMission', data, config)
}

function commonCheck(data: any, config?: any) {
  return post('/task/checkTask', data, config)
}

function dailyClaim(data: any, config?: any) {
  return post('/mission/claimReward', data, config)
}

function commonClaim(data: any, config?: any) {
  return post('/task/claimTaskReward', data, config)
}

function commonFinish(data: any, config?: any) {
  return post('/task/finishTask', data, config)
}

function inviteFinish(data: any, config?: any) {
  return post('/task/finishInviteTask', data, config)
}

function upgradeFinish(data: any, config?: any) {
  return post('/task/finishUserUpgradeTask', data, config)
}

function skinFinish(data: any, config?: any) {
  return post('/task/finishCryptocurrencyExchangeTask', data, config)
}

function dailyFinish(data: any, config?: any) {
  return post('/mission/finishDailyMission', data, config)
}

function totalFinish(data: any, config?: any) {
  return post('/task/claimBonus', data, config)
}

export default {
  // get info
  info,
  list,
  skinList,
  dailyList,
  inviteList,
  commonList,
  upgradeList,
  finishBonus,
  // post click
  dailyClick,
  commonClick,
  // post check
  dailyCheck,
  commonCheck,
  // post finish
  dailyClaim,
  commonClaim,
  skinFinish,
  dailyFinish,
  inviteFinish,
  commonFinish,
  upgradeFinish,
  totalFinish,
}
