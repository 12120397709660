import en from './en.json'
import ru from './ru.json'
import ko from './ko.json'
import tr from './tr.json'
import id from './id.json'
import bn from './bn.json'
import vi from './vi.json'
import hi from './hi.json'
import es from './es.json'
import fr from './fr.json'

// export i18n language list
export default {
  en,
  ru,
  ko,
  tr,
  id,
  bn,
  vi,
  hi,
  es,
  fr,
}
