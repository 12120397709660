import { TonConnectUI } from '@tonconnect/ui'
import type { WalletInfo } from '@tonconnect/ui'
import { Apis } from '@/api'
import { isMobile } from '@/constants/is'
import { getWalletFriendlyAddress } from '@/utils/wallet'
import { useWalletStoreRefs } from '@/store/modules/wallet'

class TonWalletClient {
  /**
   * client TonConnectUI
   */
  twcWallet: TonConnectUI = {} as TonConnectUI

  /**
   * wallet list
   */
  twcWalletList: WalletInfo[] = {} as WalletInfo[]

  /**
   * request wallet list
   * @returns {Promise<string, any>} TonConnectUI Data
   */
  async requestWalletList() {
    if (this.twcWalletList.length > 0) {
      return Promise.resolve(this.twcWalletList)
    }
    else {
      const walletList = await this.twcWallet.getWallets()
      if (walletList.length > 0) {
        this.twcWalletList = walletList
      }
      return walletList
    }
  }

  /**
   * request backend to get binding status
   * @returns {Promise<string, any>} backend data
   */
  async requestBindingStatus() {
    const { address } = useWalletStoreRefs()
    const list = await Apis.wallet.info() as any
    if (list.length === 0) {
      address.value = ''
      return
    }
    if (list[0].friendlyAddress && list[0].friendlyAddress.length > 0)
      address.value = list[0].friendlyAddress
    else
      address.value = list[0].rawAddress ?? ''
  }

  /**
   * request backend to bind address
   * @returns {Promise<string, boolean>} bind status
   */
  async requestBindAddress() {
    const { wallet, address } = useWalletStoreRefs()
    const bindPayload = {
      walletType: 1,
      publicKey: wallet.value.account.publicKey,
      friendlyAddress: getWalletFriendlyAddress(wallet.value.account.address),
      rawAddress: wallet.value.account.address,
    }
    const status = await Apis.wallet.bind(bindPayload) as any
    if (status)
      address.value = bindPayload.friendlyAddress

    return status
  }

  /**
   * request backend to bind address(okx wallet)
   * @returns {Promise<string, boolean>} bind status
   */
  async requestSingleWalletBindAddress(walletType: number = 2) {
    const { address } = useWalletStoreRefs()
    let singleWallet: any = null
    if (walletType === 3) {
      const { bitgetWallet } = useWalletStoreRefs()
      singleWallet = bitgetWallet.value
    }
    else {
      const { okxWallet } = useWalletStoreRefs()
      singleWallet = okxWallet.value
    }
    const bindPayload = {
      walletType,
      publicKey: singleWallet.publicKey,
      friendlyAddress: getWalletFriendlyAddress(singleWallet.address),
      rawAddress: singleWallet.address,
    }
    const status = await Apis.wallet.bindSingle(bindPayload) as any
    if (status)
      address.value = bindPayload.friendlyAddress

    return status
  }

  /**
   * request backend to unbind address
   * @returns {Promise<string, boolean>} unbind status
   */
  async requestUnbindAddress() {
    const status = await Apis.wallet.unbind() as any
    if (status) {
      // clear wallet address
      const { address, wallet, okxWallet, bitgetWallet } = useWalletStoreRefs()
      address.value = ''
      // disconnect wallet
      this.twcWallet.disconnect()
      // clear wallet
      wallet.value = null
      okxWallet.value = null
      bitgetWallet.value = null
    }
    return status
  }
}

export const TWClient = new TonWalletClient()

export function setupTonWallet() {
  const { wallet, modalState } = useWalletStoreRefs()

  // init TonWallet
  const TonWallet = new TonConnectUI({
    manifestUrl: 'https://www.yescoin.gold/tonconnect-manifest.json',
  })

  // TonConnectUI UI options
  if (!isMobile()) {
    TonWallet.uiOptions = {
      actionsConfiguration: {
        twaReturnUrl: 'https://t.me/theYescoin_bot/Yescoin',
      },
    }
  }

  /**
   * @warn clear wallet data
   * telegram switch account use the same wallet
   */
  wallet.value = null

  // Listen to modal state changes
  TonWallet.onModalStateChange((state) => {
    modalState.value = state.status === 'opened'
  })

  // Listen to wallet state changes
  TonWallet.onStatusChange((walletAndwalletInfo) => {
    wallet.value = walletAndwalletInfo
  })

  // update wallet
  TWClient.twcWallet = TonWallet
  TWClient.requestBindingStatus()
}
