import { defineStore, storeToRefs } from 'pinia'

interface State {
  game: any
}
const useGameStore = defineStore('GameStore', {
  state: (): State => {
    return {
      game: null,
    }
  },
})

export const useGameStoreRefs = () => storeToRefs(useGameStore())
