<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { Picker, Popover, Popup } from 'vant'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useIntervalFn } from '@vueuse/core'
import type { BackendResponseData } from 'axios'
import dayjs from 'dayjs'
// components
import YBackground from '@/components/YBackground.vue'
// utils
import { ENV } from '@/constants/env'
import { useSetupAssets } from '@/assets'
import { useUserStoreRefs } from '@/store/modules/user'
import { useGameStoreRefs } from '@/store/modules/game'
import { useGlobalStoreRefs } from '@/store/modules/global'
import { Apis } from '@/api'
import { useApiClient } from '@/api/hooks/useClient'
import { TGClient } from '@/services/telegram'
import { setGlobalLocale } from '@/services/i18n'
import { logError } from '@/services/sentry'
import { LOCALE_LIST } from '@/constants/locale'
import { SKIN_NAME_LIST } from '@/constants/skin'

const { t } = useI18n()
const router = useRouter()
const { game } = useGameStoreRefs()
const { token, user, avatar } = useUserStoreRefs()
const {
  yescoinSkin,
  isReloadHomeData,
  isReloadTaskStatus,
  isReloadBonusStatus,
  yescoinCareerTips,
} = useGlobalStoreRefs()
const { progress, execute } = useSetupAssets()
// Setting Params
const isSettingPopoverShow = ref(false)
const settingActionList = ref([
  { text: t('common.language'), value: 'language' },
  { text: t('common.privacy.policy'), value: 'privacy-policy' },
])
// Locale Params
const isLocaleShow = ref(false)

const { isFetching } = useApiClient(async () => {
  await execute().catch((error) => {
    logError(error)
  })
  if (TGClient.supported) {
    // User Login
    const { token: newToken, userAvatar } = await Apis.user.login(decodeURIComponent(TGClient.initDataRaw as string)) as BackendResponseData
    token.value = newToken
    avatar.value = userAvatar
  }
  if (!token.value)
    return

  // Home Path
  const isToHome = location.pathname === '/' || location.pathname === ''
  // init info
  if (isToHome) {
    // account & game & skin
    const list = await Promise.all([
      Apis.user.account(),
      Apis.game.info(),
      Apis.skin.list(),
    ])
    user.value = list[0] as any
    game.value = list[1] as any
    onUpdateYesSkin(list[2] as any)
  }
  else {
    // account & skin
    const list = await Promise.all([
      Apis.user.account(),
      Apis.skin.list(),
    ])
    user.value = list[0] as any
    onUpdateYesSkin(list[1] as any)
  }
  // another page reload home data
  isReloadHomeData.value = !isToHome
  isReloadTaskStatus.value = true
  isReloadBonusStatus.value = true
  if (yescoinCareerTips.value) {
    yescoinCareerTips.value = false
    // check user first login: less than 24 hours
    const userInfo = await Apis.user.info() as any
    const nowTimestamp = dayjs()
    const countdownDateDayjs = dayjs(userInfo.gmtCreateTime).add(1, 'day')
    if (nowTimestamp.isBefore(countdownDateDayjs)) {
      router.push({ name: 'Introduction' })
    }
    else {
      router.push({ name: 'Career', params: { type: 'introduction' } })
    }
  }
})
const { execute: userLeaveExecute } = useApiClient(async () => {
  await Apis.user.leave() as any
}, { immediate: false })

// Interval Init
const { pause: offlinePause, resume: offlineResume } = useIntervalFn(() => {
  userLeaveExecute()
}, 8000, { immediate: false })

/**
 * @msg vue life circle
 */
onMounted(async () => {
  await nextTick()
  // fix: ios css :active effective
  document.addEventListener('touchstart', () => {}, false)
  // listen setting button click
  if (TGClient.supported)
    onSettingButtonClick()
})

onUnmounted(() => {
  document.removeEventListener('touchstart', () => {}, false)
})

watch(() => isReloadBonusStatus.value, (newValue) => {
  if (newValue === false) {
    offlineResume()
  }
  else {
    offlinePause()
  }
})

// upd yes skin
function onUpdateYesSkin(detail: any) {
  const skinList = detail.originalList.concat(detail.yesSummerList)
  const selectedSkin = skinList.find((skin: any) => skin.skinStatus === 2)
  if (selectedSkin) {
    const skinIndex = SKIN_NAME_LIST.findIndex(item => item.value === selectedSkin.skinName)
    yescoinSkin.value = skinIndex > -1 ? selectedSkin.skinName : SKIN_NAME_LIST[0].value
  }
  else {
    yescoinSkin.value = SKIN_NAME_LIST[0].value
  }
}

// user click setting button
function onSettingButtonClick() {
  TGClient.settingsButton.on('click', () => {
    isSettingPopoverShow.value = true
  })
}

// user select setting popover
function onSelectPopoverAction({ value }: { value: string }) {
  if (value === 'language') {
    isLocaleShow.value = true
  }
  else if (value === 'privacy-policy') {
    TGClient.shareOuterLink(ENV.USER_PRIVACY_POLICY)
  }
}

// user select locale
function onLocaleConfirm({ selectedValues }: { selectedValues: string[] }) {
  isLocaleShow.value = false
  const [locale] = selectedValues
  setGlobalLocale(locale)
  // upd action list
  settingActionList.value = [
    { text: t('common.language'), value: 'language' },
    { text: t('common.privacy.policy'), value: 'privacy-policy' },
  ]
}
</script>

<template>
  <YBackground :loading="isFetching" :progress="progress">
    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.transition || 'fade'" mode="out-in">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
    <Popover
      v-model:show="isSettingPopoverShow"
      :overlay="true"
      :show-arrow="false"
      teleport="body"
      placement="bottom-end"
      :actions="settingActionList" @select="onSelectPopoverAction"
    >
      <template #reference>
        <div class="system-setting-button" style="width: 0; height: 0;" />
      </template>
    </Popover>
    <Popup v-model:show="isLocaleShow" round position="bottom" teleport="body">
      <Picker
        :title="t('common.language')"
        :columns="LOCALE_LIST"
        @cancel="isLocaleShow = false"
        @confirm="onLocaleConfirm"
      />
    </Popup>
  </YBackground>
</template>

<style lang="less" scoped>
:deep(.van-popover__wrapper) {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 0px;
  height: 0px;
}
</style>
