import type { App } from 'vue'
import type { RouteLocationNormalized, RouteRecordName } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { TGClient } from '@/services/telegram'
import { useUserStoreRefs } from '@/store/modules/user'

// router
const Routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home/index.vue'),
    meta: {
      title: 'Home - Yescoin',
    },
  },
  {
    path: '/earn',
    name: 'Earn',
    component: () => import('@/views/Earn/index.vue'),
    meta: {
      title: 'Earn - Yescoin',
    },
  },
  {
    path: '/friends',
    name: 'Friends',
    component: () => import('@/views/Friends/index.vue'),
    meta: {
      title: 'Friends - Yescoin',
    },
  },
  {
    path: '/boosts',
    name: 'Boosts',
    component: () => import('@/views/Boosts/index.vue'),
    meta: {
      title: 'Boosts - Yescoin',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile/index.vue'),
    meta: {
      title: 'Profile - Yescoin',
    },
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import('@/views/Leaderboard/index.vue'),
    meta: {
      title: 'Leaderboard - Yescoin',
    },
  },
  {
    path: '/squad',
    name: 'Squad',
    component: () => import('@/views/Squad/index.vue'),
    meta: {
      title: 'Squad - Yescoin',
    },
  },
  {
    path: '/squad-detail',
    name: 'SquadDetail',
    component: () => import('@/views/SquadDetail/index.vue'),
    meta: {
      title: 'Squad Details - Yescoin',
    },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import('@/views/Invite/index.vue'),
    meta: {
      title: 'Gift Box - Yescoin',
    },
  },
  {
    path: '/invite-code',
    name: 'InviteCode',
    component: () => import('@/views/InviteCode/index.vue'),
    meta: {
      title: 'Invite Code - Yescoin',
    },
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: () => import('@/views/Introduction/index.vue'),
    meta: {
      title: 'Yescoin',
    },
  },
  {
    path: '/check-in',
    name: 'CheckIn',
    component: () => import('@/views/CheckIn/index.vue'),
    meta: {
      title: 'Check In - Yescoin',
    },
  },
  {
    path: '/yes-crazy',
    name: 'YesCrazy',
    component: () => import('@/views/YesCrazy/index.vue'),
    meta: {
      title: 'YesCrazy - Yescoin',
    },
  },
  {
    path: '/yes-skin',
    name: 'YesSkin',
    component: () => import('@/views/YesSkin/index.vue'),
    meta: {
      title: 'YesSkin - Yescoin',
    },
  },
  {
    path: '/cooperation',
    name: 'Cooperation',
    component: () => import('@/views/Cooperation/index.vue'),
    meta: {
      title: 'Cooperation - Yescoin',
    },
  },
  {
    path: '/career/:type?',
    name: 'Career',
    component: () => import('@/views/Career/index.vue'),
    meta: {
      title: 'Career - Yescoin',
    },
  },
  {
    path: '/okx-cooperation',
    redirect: '/cooperation',
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: Routes,
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

const COOP_EVENT_LIST: string[] = [
  'okx_wallet_event_1',
  'bitget_wallet_event_1',
  'bingx_event_1',
  'hashkey_event_1',
  'bybit_web3_event_1',
  'binance_web3_event_1',
]
export const WHITE_LIST: RouteRecordName[] = [
  'Home',
  'Invite',
  'Squad',
  'Introduction',
  'OkxCooperation',
]

// config router
export function setupRouter(app: App) {
  app.use(router)
  const { token, tgUser } = useUserStoreRefs()
  // Routing Guard: 拦截未登录用户
  router.beforeEach((to: RouteLocationNormalized) => {
    if (TGClient.supported) {
      if (to.name === 'Home')
        TGClient.backButton.hide()
      else
        TGClient.backButton.show()
    }
    if (to.name && WHITE_LIST.includes(to.name)) {
      return true
    }
    else {
      if (token.value || tgUser.value)
        return true
      else
        return { name: 'Home' }
    }
  })

  // Set Page Visited Count
  let isVisitNext = true
  const visitedHistory = new Set()
  router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (isVisitNext) {
      visitedHistory.add(to.name)
      if (from.name === 'Squad' && to.name === 'SquadDetail')
        visitedHistory.delete(from.name)
    }
    else {
      isVisitNext = true
      visitedHistory.delete(from.name)
    }
    return true
  })

  if (!TGClient.supported)
    return

  let isInitBack = false
  const initData = TGClient.initData
  if (initData && initData.startParam) {
    // redirect to the target page
    const startParamString = initData.startParam as string
    const squadInviteStatus = startParamString.includes('_')
    if (COOP_EVENT_LIST.includes(startParamString)) {
      router.replace({
        name: 'Cooperation',
        query: {
          coopEvent: startParamString,
        },
      })
      isInitBack = true
    }
    else if (squadInviteStatus) {
      const inviteCode = startParamString.slice(0, startParamString.indexOf('_'))
      const inviteSquadLink = startParamString.slice(startParamString.indexOf('_') + 1)
      if (inviteCode.length === 6 && inviteSquadLink.length > 0) {
        router.replace({
          name: 'Squad',
          query: {
            inviteCode,
            inviteSquadLink,
          },
        })
        isInitBack = true
      }
      else {
        router.replace({
          name: 'Home',
        })
      }
    }
    else if (startParamString.length === 6) {
      router.replace({
        name: 'Invite',
        query: { inviteCode: initData.startParam },
      })
      isInitBack = true
    }
  }

  // set TGClient Back Button
  TGClient.backButton.on('click', () => {
    if (visitedHistory.size > 1 && !isInitBack) {
      isVisitNext = false
      router.back()
    }
    else { router.push('/') }
    // update
    isInitBack = false
  })
}
