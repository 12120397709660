import { defineStore, storeToRefs } from 'pinia'

interface State {
  wallet: any
  okxWallet: any
  bitgetWallet: any
  address: string
  modalState: boolean
}
const useWalletStore = defineStore('WalletStore', {
  state: (): State => {
    return {
      wallet: null,
      okxWallet: null,
      bitgetWallet: null,
      address: '', // backend data
      modalState: false,
    }
  },
})

export const useWalletStoreRefs = () => storeToRefs(useWalletStore())
