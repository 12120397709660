import user from './user'
import game from './game'
import task from './task'
import skin from './skin'
import squad from './squad'
import wallet from './wallet'
import boosts from './boosts'
import friend from './friend'
import checkIn from './check-in'
import secretCode from './secret-code'
import leaderboard from './leaderboard'

export const Apis = {
  user,
  game,
  task,
  skin,
  squad,
  wallet,
  boosts,
  friend,
  checkIn,
  secretCode,
  leaderboard,
}
