import { get, post } from '../client'

function list() {
  return get('/signIn/list')
}

function info() {
  return get('/operation/dailyPost')
}

function checkIn(data: any, config?: any) {
  return post('/signIn/claim', data, config)
}

export default {
  info,
  list,
  checkIn,
}
