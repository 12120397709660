import { get, post } from '../client'

function info() {
  return get('/secretCode/info')
}

function check() {
  return get('/secretCode/check')
}

function claim(data: any, config?: any) {
  return post('/secretCode/claim', data, config)
}

export default {
  info,
  check,
  claim,
}
