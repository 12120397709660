import { defineStore, storeToRefs } from 'pinia'

const useGlobalStore = defineStore('GlobalStore', {
  state: () => ({
    swipeTips: true,
    yescoinSkin: 'Yescoin',
    yescoinBackground: '',
    yescoinCareerTips: true,
    createTime: '',
    chestStatus: false,
    shakingStatus: false,
    checkInStatus: false,
    swipeBotStatus: false,
    isReloadHomeData: false,
    isReloadChestData: false,
    isInvitedStatus: false,
    isReloadTaskStatus: true,
    isReloadBonusStatus: true,
  }),
})

export const useGlobalStoreRefs = () => storeToRefs(useGlobalStore())
