import { get, post } from '../client'

function info() {
  return get('/build/getAccountBuildInfo')
}

function starInfo() {
  return get('/tgStar/getBuildItem')
}

function starStatus(query: any) {
  return get('/tgStar/checkPayment', { params: query })
}

function claim(data: any, config?: any) {
  return post('/build/levelUp', data, config)
}

function swipeBotSwitch(data: any, config?: any) {
  return post('/build/toggleSwipeBotSwitch', data, config)
}

export default {
  info,
  claim,
  starInfo,
  starStatus,
  swipeBotSwitch,
}
