<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, ref, toRefs } from 'vue'
import { Progress } from 'vant'
// utils
import { ENV } from '@/constants/env'
import { isiOS } from '@/constants/is'
import { TGClient } from '@/services/telegram'
import { IMAGES } from '@/assets'
import { useGlobalStoreRefs } from '@/store/modules/global'

interface Props {
  loading?: boolean
  progress?: number
}
const props = withDefaults(defineProps<Props>(), {
  loading: true,
  progress: 0,
})
const { loading, progress } = toRefs(props)
// Reactive Params
const launchStatus = ref(true)
const { yescoinBackground, chestStatus, shakingStatus } = useGlobalStoreRefs()
const isShakeBackground = computed(() => shakingStatus?.value)
const isCompletedStatus = computed(() => (!loading.value && !launchStatus.value))
// bg class config
const launchBgClass = computed(() => (isCompletedStatus.value ? 'completed-background' : ''))
const loadingBgClass = computed(() => (!isCompletedStatus.value ? 'loading-background' : ''))
const tempLaunchBgClass = computed(() => {
  if (chestStatus?.value) {
    return 'chest-background'
  }
  else {
    return yescoinBackground.value
  }
})

// vue event
onBeforeMount(() => {
  chestStatus.value = false
  shakingStatus.value = false
  yescoinBackground.value = ''
})

onMounted(async () => {
  await nextTick()
  setTimeout(() => {
    launchStatus.value = false
  }, 1000)
})
</script>

<template>
  <div class="container" :class="{ 'shake-animation': isShakeBackground, 'ios-container': isiOS() }">
    <div
      class="launch-background"
      :class="[launchBgClass, tempLaunchBgClass, loadingBgClass]"
    />
    <div v-if="!isCompletedStatus" class="launch-screen">
      <div class="launch-area">
        <div class="launch-done">
          <Progress class="launch-progress" color="linear-gradient(0deg, #FFAF00 -16.13%, #FFD700 100%)" track-color="linear-gradient(0deg, #D8E1EC -22.58%, #B3C3D8 87.1%)" :percentage="progress" :show-pivot="false" />
          <div class="coop-link-panel">
            <img class="coop-link-icon" :src="IMAGES.BLACK_ICON" @click="TGClient.shareOuterLink(ENV.TW_FOLLOW_LINK)">
            <img class="coop-link-icon" :src="IMAGES.BLUE_ICON" @click="TGClient.shareLink(ENV.TG_CHANNEL_LINK, false)">
            <img class="coop-link-icon" :src="IMAGES.TON_COOP_ICON" @click="TGClient.shareOuterLink(ENV.TON_COOP_LINK)">
          </div>
        </div>
      </div>
    </div>
    <div v-else class="launch-content">
      <slot />
    </div>
  </div>
</template>

<style lang="less" scoped>
.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overscroll-behavior-y: none;
  &.ios-container {
    height: 100vh;
  }
  &.shake-animation {
    animation: screen-shake-animation 320ms linear infinite;
    -webkit-animation: screen-shake-animation 320ms linear infinite;
  }
  .launch-background {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 480px;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    &.completed-background {
      background-image: url('@/assets/images/default-bg.png');
      transition: background-image 320ms;
      -webkit-transition: background-image 320ms;
    }
    &.chest-background {
      background-image: url('@/assets/images/chest-bg.png');
    }
    &.earn-background {
      background-image: url('@/assets/images/earn-bg.png');
    }
    &.loading-background {
      background-image: url('@/assets/images/ton-coop-yes-bg.png');
    }
  }
  .launch-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    overscroll-behavior: none;
  }
  .launch-screen {
    position: relative;
    padding: 0 40px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .launch-area {
      position: relative;
      margin-top: 47vh;
      margin-bottom: 4vh;
      height: 118px;
      .launch-done {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .launch-progress {
          position: relative;
          margin-top: 12px;
          width: 200px;
          height: 24px;
          border-radius: 32px;
          border: 2px solid #6D8CB1;
        }
        .coop-link-panel {
          position: relative;
          margin-top: 34px;
          opacity: 0;
          display: flex;
          justify-content: center;
          .coop-link-icon {
            position: relative;
            margin-left: 28px;
            height: 48px;
            object-fit: contain;
            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 385px){
  .container {
    .launch-screen .launch-area {
      .launch-done {
        .coop-link-panel {
          .coop-link-icon {
            height: 58px;
          }
        }
      }
    }
  }
}
</style>
