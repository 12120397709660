// Local Image
import SKIN_GIF from './skin-anim.gif'
import EARN_BG from './earn-bg.png'
import CHEST_BG from './chest-bg.png'
import DEFAULT_BG from './default-bg.png'
import TON_COOP_YES_BG from './ton-coop-yes-bg.png'
import COIN from './coin.png'
import COIN_SVG from './coin.svg'
import OKX_ICON from './okx-icon.png'
import BINGX_ICON from './bingx-icon.png'
import BYBIT_ICON from './bybit-icon.png'
import BITGET_ICON from './bitget-icon.png'
import HASHKEY_ICON from './hashkey-icon.png'
import BINANCE_ICON from './binance-icon.png'
import UPCOMING_ICON from './upcoming-icon.png'
import COMBINE_COIN from './coin-combine.png'
import BLUE_ICON from './blue-icon.png'
import BLACK_ICON from './black-icon.png'
import TON_COOP_ICON from './ton-coop-icon.png'
import RED_DOT_ICON from './earn-todo-icon.png'
import DAILY_TASK_ICON from './daily-task-icon.png'
import PAC_MAN_1 from './pac-man-1.png'
import PAC_MAN_2 from './pac-man-2.png'
import PAC_MAN_3 from './pac-man-3.png'
import CHEST_SVG from './chest-icon.svg'
import CHEST_COIN_SVG from './chest-coin.svg'
import CHEST_OPEN_SVG from './chest-open-icon.svg'
import SLOGAN_ICON from './slogan-icon.png'
import PARTICLE_SVG from './particle.svg'
import POPUP_BTN_BG from './popup-btn.png'
import POPUP_NEW_BTN_BG from './popup-new-btn.png'
import POPUP_BYBIT_ICON from './bybit-popup-icon.png'
import POPUP_TASK_ICON from './popup-task-icon.png'
import POPUP_SHORT_BTN_BG from './popup-short-btn.png'
import POPUP_PURPLE_BTN_BG from './popup-purple-btn.png'
import POPUP_SHORT_PINK_BTN_BG from './popup-pink-short-btn.png'
import POPUP_SHORT_GRAY_BTN_BG from './popup-gray-short-btn.png'
import POPUP_SHORT_GREEN_BTN_BG from './popup-green-short-btn.png'
import POPUP_MECH_BTN_BG from './popup-mechanical-btn.png'
import POPUP_SHORT_MECH_BTN_BG from './popup-mechanical-short-btn.png'
import POPUP_SHORT_MECH_ACTIVE_BTN_BG from './popup-mechanical-short-active-btn.png'
import POPUP_PURPLE_SHORT_MECH_BTN_BG from './popup-purple-mechanical-short-btn.png'
import SQUAD_ICON from './squad-icon.png'
import INVITE_ICON from './invite-icon.png'
import SWIPE_ICON from './swipe-icon.png'
import ROCKET_ICON from './rocket-icon.png'
import GIFT_BOX_ICON from './friends-icon.png'
import SQUAD_COMMUNITY_NEW from './squad-community-new.png'
import HOME_TOP_BG from './home-top-summary-bg.png'
import HOME_TOP_NAME_BG from './home-top-name-bg.png'
import HOME_TOP_COIN_BG from './home-top-coin-bg.png'
import HOME_TOP_AVATAR_BG from './home-top-avatar-bg.png'
import HOME_TOP_COIN_ICON from './home-top-coin-icon.png'
import HOME_TOP_LEVEL_ICON from './home-top-level-icon.png'
import HOME_DAILY_NORMAL_BG from './home-daily-normal.png'
import HOME_SQUAD_NORMAL_BG from './home-squad-normal.png'
import HOME_SQUAD_ACTIVE_BG from './home-squad-active.png'
import HOME_DAILY_NORMAL_ICON from './home-daily-normal-icon.png'
import HOME_DAILY_TODO_ICON from './home-daily-normal-todo.png'
import HOME_DAILY_DONE_ICON from './home-daily-normal-done.png'
import HOME_SQUAD_NORMAL_ICON from './home-squad-normal-icon.png'
import HOME_SWIPE_TIPS from './home-swipe-tips.png'
import HOME_BOTTOM_BG from './home-bottom-bg.png'
import HOME_EARN_EMPTY_BTN from './home-earn-empty.png'
import HOME_BUILD_EMPTY_BTN from './home-build-empty.png'
import HOME_FRIEND_EMPTY_BTN from './home-friend-empty.png'
import HOME_PROFILE_EMPTY_BTN from './home-profile-empty.png'
import HOME_BOTTOM_BOOST from './home-bottom-boost.png'
import HOME_BOTTOM_YESPAC from './home-bottom-yespac.png'
import HOME_BOTTOM_YESPAC_BG from './home-bottom-yespac-bg.png'
import HOME_BOTTOM_YESPAC_GIF from './home-bottom-yespac.gif'
import HOME_BRONZE_ICON from './home-bronze-icon.png'
import HOME_SILVER_ICON from './home-silver-icon.png'
import HOME_GOLD_ICON from './home-gold-icon.png'
import HOME_PLATINUM_ICON from './home-platinum-icon.png'
import HOME_DIAMOND_ICON from './home-diamond-icon.png'
import HOME_PROGRESS_BTN from './home-progress-btn.png'
import HOME_PROGRESS_ACTIVE_HEAD from './home-progress-active-head.png'
import HOME_PROGRESS_ACTIVE_CENTER from './home-progress-active-center.png'
import HOME_PROGRESS_ACTIVE_TAIL from './home-progress-active-tail.png'
import HOME_PROGRESS_INACTIVE_HEAD from './home-progress-inactive-head.png'
import HOME_PROGRESS_INACTIVE_TAIL from './home-progress-inactive-tail.png'
import HOME_PROGRESS_INACTIVE_CENTER from './home-progress-inactive-center.png'
import HOME_SKIN_BG from './home-skin-bg.png'
import INTRO_BG from './intro-bg.png'
import INTRO_BANNER_1 from './intro-banner-1.png'
import INTRO_BANNER_2 from './intro-banner-2.png'
import INTRO_BANNER_3 from './intro-banner-3.png'
import INTRO_BANNER_4 from './intro-banner-4.png'

export const IMAGES = {
  COIN,
  OKX_ICON,
  BINGX_ICON,
  BYBIT_ICON,
  BITGET_ICON,
  HASHKEY_ICON,
  BINANCE_ICON,
  COIN_SVG,
  SKIN_GIF,
  EARN_BG,
  CHEST_BG,
  DEFAULT_BG,
  UPCOMING_ICON,
  TON_COOP_YES_BG,
  SQUAD_ICON,
  INVITE_ICON,
  SWIPE_ICON,
  ROCKET_ICON,
  COMBINE_COIN,
  BLUE_ICON,
  BLACK_ICON,
  TON_COOP_ICON,
  RED_DOT_ICON,
  DAILY_TASK_ICON,
  PARTICLE_SVG,
  SLOGAN_ICON,
  PAC_MAN_1,
  PAC_MAN_2,
  PAC_MAN_3,
  CHEST_SVG,
  CHEST_COIN_SVG,
  CHEST_OPEN_SVG,
  POPUP_BTN_BG,
  POPUP_BYBIT_ICON,
  POPUP_NEW_BTN_BG,
  POPUP_TASK_ICON,
  POPUP_SHORT_BTN_BG,
  POPUP_PURPLE_BTN_BG,
  POPUP_SHORT_PINK_BTN_BG,
  POPUP_SHORT_GRAY_BTN_BG,
  POPUP_SHORT_GREEN_BTN_BG,
  POPUP_MECH_BTN_BG,
  POPUP_SHORT_MECH_BTN_BG,
  POPUP_SHORT_MECH_ACTIVE_BTN_BG,
  POPUP_PURPLE_SHORT_MECH_BTN_BG,
  GIFT_BOX_ICON,
  SQUAD_COMMUNITY_NEW,
  HOME_BRONZE_ICON,
  HOME_SILVER_ICON,
  HOME_GOLD_ICON,
  HOME_PLATINUM_ICON,
  HOME_DIAMOND_ICON,
  HOME_SWIPE_TIPS,
  HOME_TOP_BG,
  HOME_TOP_NAME_BG,
  HOME_TOP_COIN_BG,
  HOME_TOP_AVATAR_BG,
  HOME_TOP_COIN_ICON,
  HOME_TOP_LEVEL_ICON,
  HOME_DAILY_NORMAL_BG,
  HOME_SQUAD_NORMAL_BG,
  HOME_SQUAD_ACTIVE_BG,
  HOME_DAILY_NORMAL_ICON,
  HOME_DAILY_TODO_ICON,
  HOME_DAILY_DONE_ICON,
  HOME_SQUAD_NORMAL_ICON,
  HOME_BOTTOM_BG,
  HOME_EARN_EMPTY_BTN,
  HOME_BUILD_EMPTY_BTN,
  HOME_FRIEND_EMPTY_BTN,
  HOME_PROFILE_EMPTY_BTN,
  HOME_BOTTOM_BOOST,
  HOME_BOTTOM_YESPAC,
  HOME_BOTTOM_YESPAC_GIF,
  HOME_BOTTOM_YESPAC_BG,
  HOME_PROGRESS_BTN,
  HOME_PROGRESS_ACTIVE_HEAD,
  HOME_PROGRESS_ACTIVE_CENTER,
  HOME_PROGRESS_ACTIVE_TAIL,
  HOME_PROGRESS_INACTIVE_HEAD,
  HOME_PROGRESS_INACTIVE_TAIL,
  HOME_PROGRESS_INACTIVE_CENTER,
  HOME_SKIN_BG,
  INTRO_BG,
  INTRO_BANNER_1,
  INTRO_BANNER_2,
  INTRO_BANNER_3,
  INTRO_BANNER_4,
}
