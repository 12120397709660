import { get, post } from '../client'

function info() {
  return get('/user/info')
}

function login(code: string) {
  return post('/user/login', { code })
}

function share(data: any, config?: any) {
  return post('/user/share', data, config)
}

function account() {
  return get('/account/getAccountInfo')
}

function leave(data: any = null) {
  return post('/user/offline', data)
}

function statistics() {
  return get('/user/statistics')
}

export default {
  info,
  login,
  share,
  leave,
  account,
  statistics,
}
