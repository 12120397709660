// squad share text
export function getSquadShareText(url: string) {
  return `${url}

Join my squad to earn together

🎁 +100k Yescoins as a first-time gift

🎁 🎁 🎁 +500k Yescoins if you have Telegram Premium
`
}
