import { get, post } from '../client'

function info() {
  return get('/wallet/getWallet')
}

function jettonInfo(config?: any) {
  return get('/api/v3/jetton/wallets', config)
}

function singleInfo(query?: any) {
  return get('/wallet/getOkxWallet', { params: query })
}

function bind(data: any = null) {
  return post('/wallet/bind', data)
}

function bindSingle(data: any = null) {
  return post('/wallet/bindOkx', data)
}

function unbind(data: any = null) {
  return post('/wallet/unbind', data)
}

export default {
  info,
  jettonInfo,
  bind,
  unbind,
  singleInfo,
  bindSingle,
}
