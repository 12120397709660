import { get, post } from '../client'

function list() {
  return get('/skin/getSkinList')
}

function update(data: any, config?: any) {
  return post('/skin/update', data, config)
}

export default {
  list,
  update,
}
