import md5 from 'md5'
import type { Builder } from '@ton/ton'
import { Address, JettonMaster, JettonWallet, TonClient4, beginCell, fromNano, toNano } from '@ton/ton'
import { ENV } from '@/constants/env'

// Get Wallet Friendly Address
export function getWalletFriendlyAddress(address: string) {
  return Address.parse(address).toString({ bounceable: false })
}

// Check Wallet Address
export function isWalletRawAddress(address: string) {
  return Address.isRaw(address)
}
export function isWalletFriendlyAddress(address: string) {
  return Address.isFriendly(address)
}
export function isWalletAddress(address: string) {
  return isWalletRawAddress(address) || isWalletFriendlyAddress(address)
}

/**
 * @overview Wallet Params
 */
// get balance: ton
export async function getWalletBalance(address: string) {
  const TON_CLIENT = new TonClient4({
    endpoint: ENV.API_TON_CHAIN_URL,
    timeout: 30000,
  })
  try {
    const lastSeqno = await TON_CLIENT.getLastBlock() as any
    const accountInfo = await TON_CLIENT.getAccount(lastSeqno.last.seqno, Address.parse(address)) as any
    return fromNano(accountInfo?.account?.balance?.coins)
  }
  catch (e) {
    console.error('TON Client Account API Error: ', e)
  }
}

// get transactions: ton
// export async function getWalletTransactions(address: string) {

// }

/**
 * @overview Jetton Wallet Params
 */
// Onchain Jetton Wallet Contract Address
interface JETTON_MASTER_PROPS {
  [prop: string]: string
}
export const JETTON_MASTER_OBJ: JETTON_MASTER_PROPS = {
  NOT: 'EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT',
  DOGS: 'EQCvxJy4eG8hyHBFsZ7eePxrRsUQSFE_jpptRAYBmcG_DOGS',
  USDT: 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
}

// Get Jetton Wallet
export async function getJettonWallet(tokenName: string, ownerAddress: string) {
  const jettonAddress = JETTON_MASTER_OBJ[tokenName]
  if (!jettonAddress || jettonAddress.length === 0) {
    return
  }
  const TON_CLIENT = new TonClient4({
    endpoint: ENV.API_TON_CHAIN_URL,
    timeout: 30000,
  })
  const jettonMaster = TON_CLIENT.open(JettonMaster.create(Address.parse(jettonAddress)))
  const jettonWallet = await jettonMaster.getWalletAddress(Address.parse(ownerAddress))

  return jettonWallet.toString()
}

// Get Jetton Wallet Balance
export async function getJettonWalletBalance(jettonWalletAddress: string) {
  const TON_CLIENT = new TonClient4({
    endpoint: ENV.API_TON_CHAIN_URL,
    timeout: 30000,
  })
  const jettonWallet = TON_CLIENT.open(JettonWallet.create(Address.parse(jettonWalletAddress)))
  const jettonWalletBalance = await jettonWallet.getBalance()

  return fromNano(jettonWalletBalance)
}

/**
 * @overview Check In Params
 */
// Check In Status Enum
export enum CheckinStatus {
  ToDo,
  Ready,
  InProgress,
  Completed,
}

// Check In Transaction
export interface CheckinTransaction {
  $$type: 'Checkin'
  orderId: string
}

// Check In Interface
export interface CheckInProps {
  id: string
  index?: number
  status: number
  checkIn: number
  reward: number
  openIn: number
  expired: number
  signInType: number
  minClaimReward: number
  maxClaimReward: number
  minNormalReward: number
  maxNormalReward: number
  [prop: string]: any
}

// Get Check In Config
export function getCheckInConfig(payload: any) {
  const tm = Math.floor(Date.now() / 1000)
  const abc = '6863b339db454f5bbd42ffb5b5ac9841'
  const sign = md5(payload.id + tm + abc + payload.signInType)
  return {
    headers: {
      tm,
      sign,
    },
  }
}

function storeCheckIn(src: CheckinTransaction) {
  return (builder: Builder) => {
    const b_0 = builder
    b_0.storeUint(2455459194, 32)
    b_0.storeStringRefTail(src.orderId)
  }
}
// Get Check In Transaction
export function getCheckInTransaction(txn: CheckinTransaction, validTm: number) {
  const payload = beginCell().store(storeCheckIn(txn)).endCell()
  const transaction = {
    validUntil: validTm + 360, // 360 sec
    messages: [
      {
        address: 'EQCQW9JDf-W0mON_yjPLAbpdKvK49MM6RZEk8it45XO45ECC',
        amount: toNano('0.008').toString(), // nanotons ( 1 ton = 1e9(1 billion) nanotons)
        payload: payload.toBoc().toString('base64'),
      },
    ],
  }

  return transaction
}

/**
 * @overview Offline Bonus Params
 */
// Offline Bonus Transaction
export interface OfflineBonusTransaction {
  $$type: 'OfflineBonus'
  offlineId: string
}

// Get Offline Bonus Config
export function getOfflineBonusConfig(payload: any) {
  const tm = Math.floor(Date.now() / 1000)
  const abc = '6863b339db454f5bbd42ffb5b5ac9841'
  const sign = md5(payload.id + tm + abc + payload.claimType)
  return {
    headers: {
      tm,
      sign,
    },
  }
}

function storeOfflineBonus(src: OfflineBonusTransaction) {
  return (builder: Builder) => {
    const b_0 = builder
    b_0.storeUint(3703433849, 32)
    b_0.storeStringRefTail(src.offlineId)
  }
}
// Get Offline Bonus Transaction
export function getOfflineBonusTransaction(txn: OfflineBonusTransaction, validTm: number) {
  const payload = beginCell().store(storeOfflineBonus(txn)).endCell()
  const transaction = {
    validUntil: validTm + 360, // 360 sec
    messages: [
      {
        address: 'EQD4QiIKQvdTwJTXQ22ZrmQOpOFl4S1e4s5oNQweFHaTgVGZ',
        amount: toNano('0.008').toString(),
        payload: payload.toBoc().toString('base64'),
      },
    ],
  }

  return transaction
}

/**
 * @overview NOT Coin Transaction Params
 */
// Get NOT Transaction
export interface NotCoinTransaction {
  amount: number
  destAddress: string
  forwardTonAmount: number // telegram 信息推送gas费，非零(0.000001)
  // forwardPayload?: Cell // telegram 信息推送 文本评论
}
function storeNotCoin(src: NotCoinTransaction) {
  return (builder: Builder) => {
    const b_0 = builder
    b_0.storeUint(0xF8A7EA5, 32)
    b_0.storeUint(0, 64)
    b_0.storeCoins(toNano(src.amount))
    b_0.storeAddress(Address.parse(src.destAddress)) // to address
    b_0.storeAddress(Address.parse(src.destAddress)) // response address
    b_0.storeMaybeRef(null)
    b_0.storeCoins(toNano(src.forwardTonAmount)) // forward ton amount
    b_0.storeMaybeRef(null)
  }
}
export function getNotTransaction(jettonAddress: string, txn: NotCoinTransaction, validTm: number) {
  const payload = beginCell().store(storeNotCoin(txn)).endCell()
  const transaction = {
    validUntil: validTm + 360, // 360 sec
    messages: [
      {
        address: jettonAddress,
        amount: toNano('0.008').toString(), // 如果不是ton转账，此处则为gas费，超额部分会被退回(位深 * 1e9)
        payload: payload.toBoc().toString('base64'),
      },
    ],
  }

  return transaction
}

/**
 * @overview DOGS Coin Transaction Params
 */
// Get Dogs Transaction
export function getDogsTransaction() {

}
