export const LOCALE_LIST = [
  { text: 'Eng', value: 'en' },
  { text: 'français', value: 'fr' },
  { text: 'español', value: 'es' },
  { text: 'Русский', value: 'ru' },
  { text: 'Tiếng Việt', value: 'vi' },
  { text: '한국인', value: 'ko' },
  { text: 'Indonesia', value: 'id' },
  { text: 'हिन्दी', value: 'hi' },
  { text: 'Türkçe', value: 'tr' },
  { text: 'বাংলা', value: 'bn' },
]
