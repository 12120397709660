import { IMAGES } from '@/assets'

/**
 * @overview Skin Params
 */
export const SKIN_NAME_LIST = [
  { text: 'Yescoin', value: 'Yescoin', image: IMAGES.COIN_SVG },
  { text: 'OKX', value: 'OKX', image: IMAGES.OKX_ICON },
  { text: 'Bitget', value: 'Bitget', image: IMAGES.BITGET_ICON },
  { text: 'BingX', value: 'BingX', image: IMAGES.BINGX_ICON },
  { text: 'HashKey', value: 'HashKey', image: IMAGES.HASHKEY_ICON },
  { text: 'Bybit', value: 'Bybit', image: IMAGES.BYBIT_ICON },
  { text: 'Binance', value: 'Binance', image: IMAGES.BINANCE_ICON },
]

// Skin Status Enum
export enum SkinStatus {
  Upcoming,
  ToDo,
  Active,
  Selected,
}

// Skin Button Theme Enum
export const SKIN_BUTTON_THEME_LIST = {
  [SkinStatus.Upcoming]: 'default',
  [SkinStatus.ToDo]: 'todo',
  [SkinStatus.Active]: 'active',
  [SkinStatus.Selected]: 'selected',
}

// Skin Theme Enum
export const SKIN_THEME_LIST = {
  [SkinStatus.Upcoming]: 'yes-skin-upcoming',
  [SkinStatus.ToDo]: 'yes-skin-todo',
  [SkinStatus.Active]: 'yes-skin-active',
  [SkinStatus.Selected]: 'yes-skin-selected',
}

// Skin Interface
export interface SkinProps {
  id: number
  name: string
  icon: string
  isToDo: boolean
  isActive: boolean
  isUpcoming: boolean
  isSelected: boolean
  loading?: boolean
  description?: string
  domain?: string
  link?: string
  reloadStatus: boolean
  recheckStatus: boolean
  [prop: string]: any
}
