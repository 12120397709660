import { get, post } from '../client'

function info() {
  return get('/game/getGameInfo')
}

function chestInfo() {
  return get('/game/getSpecialBoxInfo')
}

function collect(data: any, config?: any) {
  return post('/game/collectCoin', data, config)
}

function recovery(data: any = null) {
  return post('/game/recoverCoinPool', data)
}

function chestReload(data: any = null) {
  return post('/game/specialBoxReloadPage', data)
}

function chestCollect(data: any) {
  return post('/game/collectSpecialBoxCoin', data)
}

function chestRecovery(data: any = null) {
  return post('/game/recoverSpecialBox', data)
}

function offlineBonus(data: any = null) {
  return post('/game/claimOfflineYesPacBonus', data)
}

function offlineBonusInfo() {
  return get('/game/getOfflineYesPacBonusInfo')
}

function chainOfflineBonus(data: any = null, config?: any) {
  return post('/game/claimOfflineBonus', data, config)
}

export default {
  info,
  collect,
  recovery,
  chestInfo,
  chestReload,
  chestCollect,
  chestRecovery,
  offlineBonus,
  offlineBonusInfo,
  chainOfflineBonus,
}
