import { get, post } from '../client'

function info() {
  return get('/invite/getInviteGiftBoxInfo')
}

function list(query: any) {
  return get('/invite/getInvitedUserList', { params: query })
}

function share(query: any) {
  return post('/invite/sendInviteLink', null, { params: query })
}

function squadShare(query: any) {
  return post('/invite/sendSquadInviteLink', null, { params: query })
}

function claim(query: any) {
  return post('/invite/claimGiftBox', null, { params: query })
}

function inviteInfo(query: any) {
  return get('/invite/getInviteGiftBoxInfoWithCode', { params: query })
}

export default {
  info,
  list,
  share,
  squadShare,
  claim,
  inviteInfo,
}
