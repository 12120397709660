import { get, post } from '../client'

function info() {
  return get('/squad/mySquad')
}

function join(data: any = null) {
  return post('/squad/joinSquad', data)
}

function leave(data: any = null) {
  return post('/squad/leaveSquad', data)
}

function inviteInfo(query: any) {
  return get('/squad/getInviteSquad', { params: query })
}

// recommend squad list
function recommendList() {
  return get('/squad/getRecommendSquadList')
}

// squad member list
function memberList(query: any) {
  return get('/squad/getUnderSquadRankingList', { params: query })
}

// squad list
function list(query: any) {
  return get('/squad/getRankingList', { params: query })
}

export default {
  info,
  join,
  list,
  leave,
  inviteInfo,
  memberList,
  recommendList,
}
